import React from 'react';

interface Props {
  status: string;
}
const FieldError: React.FC<Props> = ({ children }) => {
  if (!children) return null;

  return <div>{children}</div>;
};

export default FieldError;
