import React, { useState } from 'react';
import VoucherRedeemForm from '../../features/voucher/VoucherRedeemForm';

interface ILocation {
  search: string;
}
interface VoucherContainerProps {
  location: ILocation;
}

const VoucherContainer: React.FC<VoucherContainerProps> = ({ location }) => {
  // Use global store
  // const dispatch = useDispatch();
  // const state = useTrackedState();

  // Use local state
  const [voucherKey, setVoucherKey] = useState<string>();
  // const [redeemed, setRedeemed] = useState(false);
  // const [voucher, setVoucher] = useState(null);

  // component created
  // useEffect(() => {}, []);

  /** Handle incoming query param with voucher redeem key **/
  if (location && location.search && !voucherKey) {
    try {
      // Split paramaters from string
      const p = location.search.replace('?', '').split('&');
      // Traverse through each parameter
      p.forEach((item) => {
        // Look for key=value
        const itemArray = item.split('=');
        if ((itemArray[0] = 'key') && itemArray[1]) {
          // Key is found in the url, set this key
          setVoucherKey(itemArray[1]);
        }
      });
    } catch (err) {
      console.warn(err);
    }
  }

  return (
    <>
      <h1>Voucher verzilveren" subtitle="Heb je een voucher ontvangen, verzilver hem op deze pagina</h1>

      <VoucherRedeemForm voucherKey={voucherKey} />
    </>
  );
};

export default VoucherContainer;
