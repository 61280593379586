import React from 'react';
import { PrimaryNav } from '../../molecules/PrimaryNav';

const version = process.env.version;

const Sidebar: React.FC = () => {
  return (
    <div className="sidebar bg-gray-900 h-16 fixed bottom-0 mt-12 md:relative md:h-screen z-10 w-full md:w-48">
      <div className="md:mt-12 md:w-48 md:fixed md:left-0 md:top-0 content-center md:content-start text-left justify-between">
        <PrimaryNav />
        version: {version}
      </div>
    </div>
  );
};

export default Sidebar;
