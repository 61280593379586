import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Routes from './routes';
import { useUserState } from './features/user/user.state';
import { fetchUser } from './features/user/user.api';
import { AxiosError } from 'axios';
// import { appState } from './store';

//import { IUser } from './features/user/user.interface';
// import { useUserState } from './features/user/user.state';
// import { useApi } from './hooks/useApi';

// const onRedirectCallback = (appState) => {
//   // Use the router's history module to replace the url
//   history.replace(appState?.returnTo || window.location.pathname);
// };

const App: React.FC = () => {
  // const state = appState;
  // console.log(state[0].);

  const userState = useUserState();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [error, setError] = useState<String>();

  const ref: any = useRef();
  useEffect(() => {
    // Fetch current user from api when authentication changed to true
    if (isAuthenticated && !ref.current) {
      console.log('isAuthenticated changed from false to true');
      const updateUser = async () => {
        try {
          const u = await fetchUser(await getAccessTokenSilently());
          console.log('uu', u);
          // userState.set(() => u.account);
        } catch (err) {
          const e = err as AxiosError;
          console.log('err', e);
          if (e.message === 'Network Error') {
            setError('Netwerk error, please refresh later');
          }
        }
      };
      updateUser();
    }
    ref.current = isAuthenticated;
    // eslint-disable-next-line
  }, [isAuthenticated]);

  // User is not yet authenticated
  if (!isAuthenticated) {
    return (
      <div className="w-full h-screen bg-yellow-400 text-center content-center">
        <h1 className="text-3xl pt-24">Logging in ... </h1>
        {error && <p>{error}</p>}
      </div>
    );
  }

  // User account is not yet fetched
  if (isAuthenticated && !userState.get()) {
    return (
      <div className="w-full h-screen bg-yellow-400 text-center content-center">
        <h1 className="text-3xl pt-24">Account laden ... </h1>
        {error && <p>{error}</p>}
      </div>
    );
  }

  // User is authenticated and account is fetched
  return (
    <div className="app">
      <Routes />;
    </div>
  );
};

export default App;
