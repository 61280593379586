import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { TransactionModel } from '../types/Transaction';

const TransactionsContainer = () => {
  // use state
  const { getAccessTokenSilently } = useAuth0();

  const [transactions, setTransactions] = useState<TransactionModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  // const transactionCount = transactions.length;
  let currentPage = 1;
  let itemsPerPage = 10;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchTransactions = async () => {
    console.log(currentPage);
    // setLoading(true);
    const url = `${process.env.REACT_APP_DHM_API_URL}/transaction`;
    const token = await getAccessTokenSilently();
    try {
      const fetchResult = await Axios({
        method: 'get',
        url,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          offset: currentPage * itemsPerPage,
        },
      });
      console.log(`Fetched ${fetchResult.data.length} transactions`);
      console.log(fetchResult.data);
      const nTransactions: TransactionModel[] = [...transactions, ...fetchResult.data];
      console.log('new', nTransactions);
      setInitialized(true);
      setLoading(false);
      setTransactions(nTransactions);
    } catch (err) {
      console.warn(err);
      // setError(err);
      setInitialized(true);
      setLoading(false);
    }
  };

  // Fetch message on component load
  useEffect(() => {
    if (!initialized && !loading) {
      setLoading(true);
      fetchTransactions();
    }
  }, [loading, initialized, fetchTransactions]);

  const loadMore = () => {
    console.log('load more');
    currentPage++;
    fetchTransactions();
  };

  if (!transactions) return null;
  return (
    <>
      <h1>Transacties" subtitle="Jouw laatste transacties</h1>

      {/* <TransactionsList transactions={transactions} /> */}
      <button onClick={loadMore}>Meer transacties laden</button>
    </>
  );
};

export default TransactionsContainer;
