import React, { useState } from 'react';
import Axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

interface IPaymentResponse {
  data: {
    id: string;
    links: {
      paymentUrl: string;
    };
  };
}

/** Validate amount **/
const Amount = (val: string) => {
  let amount;
  try {
    amount = parseInt(val);
    if (isNaN(amount)) {
      return 0;
    }
  } catch (err) {
    return 0;
  }
  return amount;
};
/** Calculate transaction costs **/
const TransactionCosts = (val: string) => {
  let amount;
  try {
    amount = parseInt(val);
    if (isNaN(amount)) {
      return 0;
    }
  } catch (err) {
    return 0;
  }
  const fixed = 2;
  const percentage = 0.05;
  return fixed + percentage * amount;
};

/** Calculate total costs **/
const TotalCosts = (val: string) => {
  return Amount(val) + TransactionCosts(val);
};

const PaymentContainer = () => {
  const { getAccessTokenSilently } = useAuth0();

  // Use local state
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [error, setError] = useState<string | null>();
  const [values, setValues] = useState<any>({
    amount: 10,
  });

  const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
    let amount = parseInt(evt.currentTarget.value);
    if (amount > 100) amount = 100;
    if (amount < 0) amount = 0;

    setValues({ amount });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    setPaymentInProgress(true);

    // let p = {
    //   amount: Amount(values.amount),
    //   costs: TransactionCosts(values.amount),
    // };

    try {
      const url = `${process.env.REACT_APP_DHM_API_URL}/payment`;
      const token = await getAccessTokenSilently();
      const response = await Axios({
        method: 'post',
        url,
        headers: { Authorization: `Bearer ${token}` },
        data: { values },
        transformResponse: (r: IPaymentResponse) => r.data,
      });
      console.log(response);
      setPaymentInProgress(true);
      setError(null);
      const { data } = response;
      if (data && data.id) {
        window.location.href = data.links.paymentUrl;
      }
    } catch (err) {
      console.warn('err', err.response.data);
      setError(`Er was een probleem met het inwisselen van je voucher: ${err.response.data.message}`);
    }
  };
  return (
    <>
      <h1>Nieuwe inleg, Voeg nieuwe Haagse Munten toe aan je account</h1>

      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="amount">Bedrag</label>
          <input
            type="number"
            name="amount"
            step="0.01"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values.amount}
          />
          <div>minimaal €1,-, maximaal €100,-</div>
          {/* <ErrorMsg>
              {errors.amount && touched.amount && errors.amount}
            </ErrorMsg> */}
        </div>

        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Omschrijving</th>
              <th>Bedrag</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Inleg</td>
              <td>{Amount(values.amount).toFixed(2)} €</td>
            </tr>
            <tr>
              <td>Transactiekosten (€2 + 5%)</td>
              <td>{TransactionCosts(values.amount).toFixed(2)} €</td>
            </tr>
            <tr>
              <td>TOTAAL</td>
              <td>{TotalCosts(values.amount).toFixed(2)} €</td>
            </tr>
          </tbody>
        </table>

        <div style={{ textAlign: 'right' }}>
          <button type="submit" disabled={paymentInProgress}>
            <span>Nieuwe inleg via iDeal betaling</span>
            <span>
              {/* <Ideal style={{ margin: '0 10px', width: '20px' }} /> */}
              &rarr;
            </span>
          </button>
          {error && <p>{error}</p>}
        </div>
      </form>
    </>
  );
};

export default PaymentContainer;
