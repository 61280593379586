import React, { createElement } from 'react';
import { Link } from 'react-router-dom';
import {
  FaArrowRight,
  FaArrowLeft,
  FaComments,
  FaCreditCard,
  // User,
  FaInfoCircle,
  FaLifeRing,
  FaList,
  FaTv,
} from 'react-icons/fa';

export const PrimaryNav: React.FC = () => {
  const menu = [
    {
      to: '/',
      exact: true,
      icon: FaTv,
      title: 'Dashboard',
    },
    {
      to: '/overschrijven',
      icon: FaArrowRight,
      title: 'Versturen',
    },
    {
      to: '/ontvangen',
      icon: FaArrowLeft,
      title: 'Ontvangen',
    },
    {
      to: '/transacties',
      icon: FaList,
      title: 'Transacties',
    },
    {
      to: '/inleg',
      icon: FaCreditCard,
      title: 'Nieuwe inleg',
    },
    {
      to: '/berichten',
      icon: FaComments,
      title: 'Berichten',
    },
    {
      to: '/voucher',
      icon: FaInfoCircle,
      title: 'Voucher',
    },
    {
      to: '/service',
      icon: FaLifeRing,
      title: 'Service',
    },
    // {
    //   to: '/contact',
    //   icon: User,
    //   title: 'Contact',
    // },
  ];

  return (
    <ul className="list-reset flex flex-row md:flex-col py-0 md:py-3 px-1 md:px-2 text-center md:text-left">
      {menu &&
        menu.map((item) => (
          <li key={item.title} className="mr-3 flex-1">
            <Link
              to={item.to}
              className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-yellow-400"
            >
              {createElement(item.icon, { className: 'pr-0 md:mr-3 inline-block' })}
              <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
                {item.title}
              </span>
            </Link>
          </li>
        ))}
    </ul>
  );
};
