import React from 'react';
import { useForm } from 'react-hook-form';

import { Form } from '../../../components/atoms/forms/Form';
import { Field } from '../../../components/atoms/forms/Field';
import { transferFormDefinition } from './transferForm.definition';

interface FormProps {}

type FormData = {
  firstName: string;
  lastName: string;
};

const TransferForm: React.FC<FormProps> = () => {
  // initialise the hook
  const { register, setValue, handleSubmit } = useForm<FormData>();
  // submit handler
  const onSubmit = handleSubmit(({ firstName, lastName }) => {
    console.log(firstName, lastName);
  }); // firstName and lastName will have correct type

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <label>First Name</label>
        <input name="firstName" ref={register} />
        <label>Last Name</label>
        <input name="lastName" ref={register} />
        <button
          type="button"
          onClick={() => {
            setValue('lastName', 'luo'); // ✅
            // setValue("firstName", true); // ❌: true is not string
            // errors.bill; // ❌: property bill does not exist
            // errors.firstName = 'no'
          }}
        >
          SetValue
        </button>
      </form>

      <Form
        action={transferFormDefinition.action}
        fields={transferFormDefinition.fields}
        render={() => (
          <React.Fragment>
            <Field {...transferFormDefinition.fields.name} />
            <Field {...transferFormDefinition.fields.email} />
            <Field {...transferFormDefinition.fields.reason} />
            <Field {...transferFormDefinition.fields.notes} />
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default TransferForm;
