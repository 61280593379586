import React from 'react';

interface Props {
  horizontal?: boolean;
  hasAddons?: boolean;
}

const FormField: React.FC<Props> = ({ children }) => {
  return <div>{children}</div>;
};

export default FormField;
