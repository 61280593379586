import React from 'react';

interface Props {
  type: string;
  validate?: string;
  disabled?: boolean;
  placeholder?: string;
  // register?: string | HTMLInputElement | RefObject<HTMLElement> | null | undefined;
  name?: string;
}
type Ref = HTMLInputElement;

const InputText = React.forwardRef<Ref, Props>(({ type, name, disabled, placeholder }, ref) => {
  const onChange = () => {};
  const onBlur = () => {};

  return (
    <input
      type={type}
      name={name}
      ref={ref}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
});

export default InputText;
