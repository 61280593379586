import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserState } from '../../../features/user/user.state';

const Header: React.FC = () => {
  const [isShown, setIsShown] = useState(false);

  const userState = useUserState();

  return (
    <nav className="bg-white border-yellow-400 border-b-2 pt-2 md:pt-1 pb-1 px-1 mt-0 h-auto fixed w-full z-20 top-0">
      <div className="flex flex-wrap items-center">
        <div className="flex flex-shrink flex-1 md:w-1/3 justify-center md:justify-start text-white">
          <Link to="/" className="block">
            <img src={'/dhm_logo_color.png'} alt="De Haagse Munt" className="block h-8 px-4" />
          </Link>
        </div>

        <div className="flex w-full pt-2 content-center flex-end justify-between md:w-1/3 md:justify-end">
          <ul className="list-reset flex justify-between flex-1 md:flex-none items-center">
            <li className="flex-1 md:flex-none md:mr-3">
              <Link
                to={'/overschrijven'}
                className="md:inline-block no-underline text-black bg-yellow-400 rounded hover:text-underline py-2 px-4 hidden"
              >
                Overschrijven
              </Link>
            </li>
            <li className="flex-1 md:flex-none md:mr-3">
              <div
                className="relative inline-block"
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
              >
                <button className="drop-button focus:outline-none">
                  Hi {userState.value?.nickname}
                  <svg className="h-3 fill-current inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>
                {isShown && (
                  <div
                    id="myDropdown"
                    className="dropdownlist absolute bg-gray-900 right-0 mt-3 p-3 overflow-auto z-30"
                  >
                    <input type="text" className="drop-search p-2 text-gray-600" placeholder="Search.." id="myInput" />
                    <Link
                      to={'/profiel'}
                      className="p-2 hover:bg-gray-800 text-sm no-underline hover:no-underline block"
                    >
                      <i className="fa fa-user fa-fw"></i> Profile
                    </Link>
                    <Link
                      to={'/settings'}
                      className="p-2 hover:bg-gray-800 text-sm no-underline hover:no-underline block"
                    >
                      <i className="fa fa-cog fa-fw"></i> Settings
                    </Link>
                    <div className="border border-gray-800"></div>
                    <Link
                      to={'/logout'}
                      className="p-2 hover:bg-gray-800 text-sm no-underline hover:no-underline block"
                    >
                      <i className="fas fa-sign-out-alt fa-fw"></i> Log Out
                    </Link>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
