import React from 'react';

const OfflineContainer = () => {
  return (
    <>
      <h1>"O no! De Haagse Munt is offline!"</h1>
    </>
  );
};

export default OfflineContainer;
