import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../../components/atoms/Button';
import FormField from '../../../components/atoms/forms/FormField';
import InputText from '../../../components/atoms/forms/InputText/inputText';
import FieldError from '../../../components/atoms/forms/FieldError';

// Interface for component props
interface Props {
  voucherKey?: string;
}

// Interface for form inputs
type Inputs = {
  voucherKey: string;
};

const VoucherRedeemForm: React.FC<Props> = ({ voucherKey }) => {
  const [completed] = useState<boolean>(false);
  // setCompleted(true);
  console.log(voucherKey);

  const { register, handleSubmit, errors } = useForm<Inputs>();
  const onSubmit = (data: any) => console.log(data);

  if (completed) {
    return (
      <div>
        <div>
          <p>Je voucher is succesvol ingewisseld</p>
          <Button to="/account">Naar mijn account &rarr;</Button>
        </div>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormField horizontal={true} hasAddons={true}>
        <InputText type="text" name="voucherKey" placeholder="Voucher code" ref={register({ required: true })} />
        <Button type="submit">Versturen</Button>
      </FormField>

      {errors.voucherKey && <FieldError status={'danger'}>This field is required</FieldError>}
    </form>
  );

  // // Local state
  // const [completed, setCompleted] = useState<boolean>(false);
  // const [key, setKey] = useState<string | undefined>(voucherKey);
  // const [error, setError] = useState<string | null>(null);

  // /
  // };

  // return (
  //   <Wrapper>
  //     {!completed && (
  //       <form onSubmit={handleRedeem} noValidate={true}>
  //         <Title>Verzilver je voucher, vul je ontvangen code hieronder in: </Title>
  //         <ItemWrapper>
  //           {/* <Label>Code: </Label> */}
  //           <Input name="voucherKey" type="text" value={key} onChange={handleChange} autoFocus />
  //           <Button type="submit">Inwisselen &rarr;</Button>
  //         </ItemWrapper>
  //         {error && <ErrorNotice>{error}</ErrorNotice>}
  //       </form>
  //     )}

  //     {completed && (
  //
  //     )}
  //   </Wrapper>
  // );
};

// const Wrapper {
//   pt-4 pb-16 margin-bottom: $spacing-4 text-align: center;`}
// `;
// const Title = styled.h3`
//   mb-8`}
// `;
// // const Label = styled.label`display: block;  w-16 color: $color-black; font-size: $fontsize-xl; font-weight: bold;`}`
// const Input = styled.input`
//   w-48 mr-2 font-mono width: 100%;  mb-8 text-align: center; shadow-inner appearance: none;  border border-gray-400 border-radius: $borderradius-default;  py-2 px-3 color: $color-gray-700;  leading-tight font-size: $fontsize-xl; focus:outline-none focus:shadow-outline`}
//   letter-spacing: 5px;
// `;
// const ItemWrapper {
//   display: flex;flex-direction: column;  w-64 margin: 0 auto;`}
// `;
// const ErrorNotice = styled.p`
//   text-red-400`}
// `;
// const Notice {
//   font-size: $fontsize-xl;`}
// `;

export default VoucherRedeemForm;
