import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  to?: string;
  disabled?: boolean;
  type?: string;
  href?: string;
  onClick?: () => void;
}

const Button: React.FC<Props> = ({ children, to, disabled, type, href }) => {
  if (href) {
    return (
      <a href={href} type={type} data-testid="button">
        {children}
      </a>
    );
  }
  if (to) {
    return (
      <Link to={to} type={type} data-testid="button">
        {children}
      </Link>
    );
  }
  return (
    <button
      className="px-2 py-1 rounded-lg bg-green-400 text-green-800 text-xl font-light uppercase shadow-md hover:shadow-lg"
      disabled={disabled}
      data-testid="button"
    >
      {children}
    </button>
  );
};

export default Button;
