import Axios from 'axios';

export const fetchUser = async (token: string) => {
  console.log('fetchUserAPI');

  // const { getAccessTokenSilently } = useAuth0();

  // const audience = process.env.REACT_APP_DHM_AUTH0_AUDIENCE;
  // const scope = 'profile';
  // const accessToken = await getAccessTokenSilently();
  const url = '/user/me';

  return await Axios(`${process.env.REACT_APP_API_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
