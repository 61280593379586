import React from 'react';
// import QRCode from 'qrcode.react';

// import {
//   // useDispatch,
//   useTrackedState,
// } from './../store';

const ReceiveContainer = () => {
  // const dispatch = useDispatch();
  // const state = useTrackedState();

  // if (!state.account) {
  //   return null;
  // }

  return (
    <>
      <h1>Ontvangen" subtitle="Laat onderstaande code scannen om direct een betaling te ontvangen</h1>

      <div>
        {/* <QRCode
            value={`https://dashboard.dehaagsemunt.nl/transfer?to=${state.account.nickname}`}
            renderAs="canvas"
            size={256}
            width="256"
            height="256"
            style={{ display: 'inline' }} 
          />*/}
      </div>
    </>
  );
};

export default ReceiveContainer;
