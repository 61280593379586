import React from 'react';
import { Router, Switch, Route, RouteProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import history from './utils/history';
import {
  OfflineContainer,
  TransferContainer,
  ReceiveContainer,
  TransactionsContainer,
  PaymentContainer,
  AccountContainer,
  MessagesContainer,
  ServiceContainer,
} from './containers';
import LoginContainer from './containers/LoginContainer/loginContainer';
import homeContainer from './containers/HomeContainer/homeContainer';
import VoucherContainer from './containers/VoucherContainer/voucherContainer';
import { Layout } from './components/organisms/Layout';

export interface ProtectedRouteProps extends RouteProps {
  component: React.FC<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

const Routes: React.FC = () => (
  <Router history={history}>
    <Layout>
      <Switch>
        <Route path="/offline" component={OfflineContainer} />
        <Route path="/login" component={LoginContainer} />
        <ProtectedRoute exact path="/" component={homeContainer} />
        <ProtectedRoute path="/overschrijven" component={TransferContainer} />
        <ProtectedRoute path="/ontvangen" component={ReceiveContainer} />
        <ProtectedRoute path="/transacties" component={TransactionsContainer} />
        <ProtectedRoute path="/inleg" component={PaymentContainer} />
        <ProtectedRoute path="/account" component={AccountContainer} />
        <ProtectedRoute path="/berichten" component={MessagesContainer} />
        <ProtectedRoute path="/voucher" component={VoucherContainer} />
        <ProtectedRoute path="/service" component={ServiceContainer} />
      </Switch>
    </Layout>
  </Router>
);

export default Routes;
