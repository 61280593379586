import React from 'react';

import TransferForm from '../features/transfer/TransferForm';

const TransferContainer = () => {
  return (
    <>
      <h1>Versturen" subtitle="Schrijf Haagse Munten over naar iemand anders</h1>

      <TransferForm />
    </>
  );
};

export default TransferContainer;
