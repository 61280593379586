import React from 'react';

const AccountContainer = () => {
  // Use global store
  // const dispatch = useDispatch();
  const state = { account: { id: null, picture: null, nickname: null, email: null, slug: null, account_plan: null } };
  const { account } = state;

  if (!account) return null;

  return (
    <>
      {/* <PageHeader title="Account details" subtitle="Jouw account" /> */}
      <div>
        <img src={account.picture || ''} alt="Profile" />
        <br />
        <strong> Username: </strong>
        {account.nickname}
        <br />
        <strong>Email: </strong>
        {account.email} <br />
        <strong>Slug: </strong>
        {account.slug}
        <br />
        <strong>Account plan: </strong>
        {account.account_plan}
        <br />
        {/* <code><pre>{JSON.stringify(user, null, 2)}</pre></code> */}
      </div>
    </>
  );
};

export default AccountContainer;
