import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '../../components/atoms/Button';
// import { AppContext } from '../../context/appContext';
// import { AccountMsg, Account } from '../../features/account/state/account.events';
// import { IBalanceState } from '../../features/balance/redux/types';
// import { AppContext } from '../../context/appContext';
// import { AccountMsg } from '../../features/account/state/accountReducer';
// import { DisplayCurrency } from '../utils/display-currency';

const HomeContainer: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  // const { dispatch } = useContext(AppContext);

  // dispatch(AccountMsg(Account.FetchAccount, { username: '1', password: '2' }));
  // const { dispatch } = useContext(AppContext);
  // dispatch(AccountMsg(Account.));
  if (!isAuthenticated) return <p>loading auth</p>;

  // if (!account || !account.account) return <p>loading account</p>;

  return (
    <>
      {/* <div>{DisplayCurrency({ value: state.balance.amount })}</div> */}
      <div style={{ textAlign: 'center' }}>
        <Button to="/overschrijven" disabled={true}>
          Nieuwe overschrijving
        </Button>
      </div>
      {/* <TransactionList transactions={transactions} /> */}
      <div style={{ textAlign: 'center' }}>
        <Button to="/transacties">Bekijk al je transacties &rarr;</Button>
      </div>
    </>
  );
};

export default HomeContainer;
