import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import history from '../../utils/history';

const LoginContainer = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    console.log('authenticated!');
    history.push('/');
  } else {
    loginWithRedirect();
  }

  return (
    <>
      <div>
        <p>Login wordt gestart..</p>
      </div>
    </>
  );
};

export default LoginContainer;
