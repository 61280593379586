/**
 *
 * This file will be responsible for exporting react-router's history module
 * so we can redirect the user programatically.
 *
 **/

import { createBrowserHistory } from 'history';

export default createBrowserHistory();
