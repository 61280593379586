import React from 'react';

const ServiceContainer = () => {
  return (
    <>
      <h1>"Service" subtitle="Vragen?</h1>

      <p>
        Vragen of opmerkingen? Stuur een mailtje naar
        <a href="mailto:info@dehaagsemunt.nl">info@dehaagsemunt.nl</a>.
      </p>
    </>
  );
};

export default ServiceContainer;
