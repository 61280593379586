import React, { useEffect, useState, useCallback } from 'react';
// import Axios from 'axios';

// import { useAuth0 } from '@auth0/auth0-react';

import { IMessage } from '../types/Message';

/**
 *
 * onInitialization -> fetchMessages()
 *  onMessagesFetched -> setSelectedMessage()
 *    onSelectedMessage -> openMessage()
 *
 */

const MessagesContainer = () => {
  // const { getAccessTokenSilently } = useAuth0();
  // Use global store
  // const dispatch = useDispatch();
  // const state = useTrackedState();

  // Use local state
  // const [loading, setLoading] = useState();
  // const [error, setError] = useState();
  const [messages] = useState<IMessage[]>([]);
  const [selectedMessageId, setSelectedMessageId] = useState<number>();

  const fetchMessages = useCallback(() => {
    // setLoading(true);
    // const url = `${process.env.REACT_APP_DHM_API_URL}/message?delete_status=false`;
    // const token = getAccessTokenSilently();
    // try {
    //   const fetchResult =aw Axios({
    //     method: 'get',
    //     url,
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    //   console.log(`Fetched ${fetchResult.data.length} messages`);
    //   setMessages(fetchResult.data);
    //   // setLoading(false);
    // } catch (err) {
    //   console.warn(err);
    //   // setError(err);
    //   // setLoading(false);
    // }
  }, []);

  // Fetch message on component load
  useEffect(() => {
    console.log('Fetch messages');
    fetchMessages();
  }, [fetchMessages]);

  // handle messages loaded
  useEffect(() => {
    if (messages.length > 0 && !selectedMessageId) {
      console.log('new messages received');
      setSelectedMessageId(messages[0].id);
    }
  }, [messages, selectedMessageId]);

  /**
   *
   * Handle when a message is selected
   *
   **/
  // const openMessage = (id: number) => {
  //   console.log('openmessage', id);
  //   // Find the message in the messages list
  //   const index = messages.findIndex((m) => m.id === id);
  //   console.log(index);
  //   // Set the selected message in the current state
  //   setSelectedMessageId(id);
  //   // Toggle status to read if not yet read
  //   if (messages[index].read_status === false) {
  //     updateReadStatus(id);
  //   }
  // };

  /**
   *
   * Delete a message
   *
   * @param {*} id
   */
  // const deleteMessage = async (id: number) => {
  //   console.log('delete message', id);
  //   // setLoading(true);
  //   const url = `${process.env.REACT_APP_DHM_API_URL}/message/${id}`;
  //   const token = await GetTokenSilently();
  //   try {
  //     const fetchResult = await Axios.put(
  //       url,
  //       { delete_status: true },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       },
  //     );
  //     console.log(`Delete status: ${fetchResult.data}`);
  //     // remove message from current list
  //     const newMessages = messages;
  //     console.log(newMessages);
  //     const index = newMessages.find((x) => x.id === id);
  //     if (index) {
  //       console.log(index);
  //       newMessages.splice(index.id, 1);
  //       setMessages(newMessages);
  //       setSelectedMessageId(messages[0].id);
  //       // setLoading(false);
  //     }
  //   } catch (err) {
  //     console.warn(err);
  //     // setError(err);
  //     // setLoading(false);
  //   }
  // };

  /**
   *
   *  Update the read status of a message
   *
   **/
  // const updateReadStatus = async (id: number) => {
  //   console.log('updating read status');
  //   const url = `${process.env.REACT_APP_DHM_API_URL}/message/${id}`;
  //   const token = await getAccessTokenSilently();
  //   try {
  //     const r = await Axios.put(
  //       url,
  //       {
  //         read_status: true,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       },
  //     );
  //     console.log(r);
  //   } catch (err) {
  //     console.warn(err);
  //     // setError(err);
  //   }
  // };

  // const currentMessage = messages.find((x) => x.id === selectedMessageId);

  return (
    <>
      {messages.length < 1 && <p>Je hebt nog geen berichten ontvangen</p>}

      {messages.length > 0 && (
        <div>
          {/* <MessagesList
              messages={messages}
              onMessageSelected={(id: number) => {
                openMessage(id);
              }}
              selectedMessageId={selectedMessageId || 0}
            /> */}
          {/* {message && (
            <MessagesDetails
              message={currentMessage }
              onDelete={(id: number) => {
                deleteMessage(id);
              }}
            /> */}
        </div>
      )}
    </>
  );
};

export default MessagesContainer;
