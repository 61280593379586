import React from 'react';
import { render } from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/browser';
import './styles.css';
import 'typeface-titillium-web';

import App from './App';

console.log(process.env.REACT_APP_AUTH0_DOMAIN);
console.log(process.env.REACT_APP_AUTH0_CLIENT_ID);
console.log(window.location.origin);
console.log(process.env.REACT_APP_AUTH0_AUDIENCE);
console.log(process.env.REACT_APP_SENTRY_DSN);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
);
