import { required, isEmail, maxLength } from '../../../utils/form-validators';
import { IFields } from '../../../components/atoms/forms/Form';

interface IFormDefinition {
  action: string;
  fields: IFields;
}

export const transferFormDefinition: IFormDefinition = {
  action: 'http://localhost:4351/api/contactus',
  fields: {
    name: {
      id: 'name',
      label: 'Name',
      validation: { rule: required },
    },
    email: {
      id: 'email',
      label: 'Email',
      validation: { rule: isEmail },
    },
    reason: {
      id: 'reason',
      label: 'Reason',
      editor: 'dropdown',
      options: ['', 'Marketing', 'Support', 'Feedback', 'Jobs'],
      validation: { rule: required },
    },
    notes: {
      id: 'notes',
      label: 'Notes',
      editor: 'multilinetextbox',
      validation: { rule: maxLength, args: 1000 },
    },
  },
};
